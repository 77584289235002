import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Check as CheckIcon } from "@mui/icons-material";

const pricingPlans = [
  {
    title: "Basic",
    price: "$49",
    period: "/month",
    bestFor: "Small businesses",
    features: ["Shift management", "Basic invoicing", "Up to 20 staff"],
  },
  {
    title: "Professional",
    price: "$99",
    period: "/month",
    bestFor: "Medium-sized operations",
    features: [
      "All Basic features",
      "Advanced reporting",
      "Up to 50 staff",
      "Resident management - Care Homes only",
    ],
  },
  {
    title: "Enterprise",
    price: "Custom",
    period: "pricing",
    bestFor: "Large agencies or chains",
    features: ["All Professional features", "API access", "Unlimited staff"],
  },
];

const CareStaffProPricing: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8, mt: "-100px" }}>
      <Box sx={{ mt: 6, mb: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Our Pricing Model
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          We offer a tiered pricing structure to accommodate care homes and
          agencies of all sizes:
        </Typography>
      </Box>

      <Grid
        container
        spacing={4}
        alignItems="flex-start"
        justifyContent="center"
      >
        {pricingPlans.map((plan) => (
          <Grid item key={plan.title} xs={12} sm={6} md={4}>
            <Card
              raised
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  align="center"
                >
                  {plan.title}
                </Typography>
                <Typography
                  variant="h3"
                  component="div"
                  gutterBottom
                  align="center"
                >
                  {plan.price}
                  <Typography
                    variant="h6"
                    component="span"
                    color="text.secondary"
                  >
                    {plan.period}
                  </Typography>
                </Typography>
                <Typography variant="subtitle1" align="center" paragraph>
                  Best for: {plan.bestFor}
                </Typography>
                <List dense>
                  {plan.features.map((feature, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <CheckIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={feature} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
              <CardActions>
                <Button fullWidth variant="contained" color="primary">
                  {plan.price === "Custom" ? "Contact Us" : "Get Started"}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 6 }}>
        <Typography variant="h5" align="center" gutterBottom>
          All plans include:
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {[
            "Free updates and new features",
            "24/7 customer support",
            "Secure cloud-based storage",
            "Mobile app access",
          ].map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="body1" align="center">
                    {feature}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* <Box sx={{ mt: 6, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Why Choose CareStaff Pro?
        </Typography>
        <List>
          {[
            'Save up to 15 hours per week on administrative tasks',
            'Reduce billing errors by up to 95%',
            'Improve staff satisfaction with clear communication and scheduling',
            'Gain valuable insights with our comprehensive analytics'
          ].map((reason, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CheckIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={reason} />
            </ListItem>
          ))}
        </List>
      </Box> */}

      <Box sx={{ mt: 6, textAlign: "center" }}>
        <Typography variant="h4" gutterBottom>
          Get Started Today
        </Typography>
        <Typography variant="body1" paragraph>
          Experience the CareStaff Pro difference with our 30-day free trial. No
          credit card required.
        </Typography>
        <Button variant="contained" color="primary" size="large">
          Start Your Free Trial
        </Button>
      </Box>
    </Container>
  );
};

export default CareStaffProPricing;
