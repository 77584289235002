import * as React from "react";
import { Grid, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Icon } from "@iconify/react";

// Import the images
import iPhoneImage from "../../assets/iphone_1.png";
import macImage from "../../assets/mac.png";

export default function Hero() {
  const theme = useTheme();

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        background: `rgba(255, 255, 255, 0.7)`,
        backgroundSize: "100% 20%",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
          background: theme.colors.gradients.bg,
        }}
      >
        <Stack spacing={2} sx={{ width: { xs: "100%", sm: "70%" } }}>
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignSelf: "center",
              textAlign: "center",
              fontSize: "clamp(3.5rem, 10vw, 4rem)",
            }}
          >
            Elevate Your Care&nbsp;
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: "center", width: { sm: "100%", md: "80%" } }}
          >
            Transform the way you manage your care services with our
            comprehensive solution. Streamline operations, manage shifts,
            generate digital timesheets, and ensure top-notch care.
          </Typography>
        </Stack>

        <Box
          sx={{
            mt: { xs: 1, sm: 1 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "text.primary",
              mb: 2,
              fontSize: "1.25rem",
            }}
          >
            Available on Android and iOS
          </Typography>
          <Stack direction="row" spacing={4}>
            <Button
              variant="outlined"
              startIcon={<Icon icon="logos:google-play-icon" />}
              component="a"
              href="#"
            >
              Android
            </Button>
            <Button
              variant="outlined"
              startIcon={<Icon icon="logos:apple-app-store" />}
              component="a"
              href="#"
            >
              iOS
            </Button>
          </Stack>
        </Box>
        <Grid container justifyContent="center" sx={{ mt: 4 }}>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                position: "relative",
                height: { xs: 300, md: 400 },
                width: "100%",
              }}
            >
              {/* Web App Screenshot */}
              <Box
                component="img"
                src={macImage}
                alt="Web App Screenshot"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "80%",
                  height: "auto",
                  borderRadius: 2,
                  boxShadow: 3,
                  zIndex: 1,
                }}
              />
              {/* Mobile App Screenshot */}
              <Box
                component="img"
                src={iPhoneImage}
                alt="Mobile App Screenshot"
                sx={{
                  position: "absolute",
                  bottom: "16%",
                  right: "8%",
                  width: "25%",
                  height: "auto",
                  borderRadius: 2,
                  boxShadow: 3,
                  zIndex: 2,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
