import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ElderlyIcon from "@mui/icons-material/Elderly";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import WorkIcon from "@mui/icons-material/Work";
import TimelineIcon from "@mui/icons-material/Timeline";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useTheme } from "@mui/material";

const items = [
  {
    icon: <ElderlyIcon />,
    title: "Resident Management",
    description:
      "Easily manage your residents, keep track of their activities and ensure personalized care.",
  },
  {
    icon: <LocalPharmacyIcon />,
    title: "Medication Tracking",
    description:
      "Monitor resident medications and ensure timely administration for their well-being.",
  },
  {
    icon: <NotificationsActiveIcon />,
    title: "Priority Alerts",
    description:
      "Receive real-time alerts for resident priorities and urgent needs.",
  },
  {
    icon: <WorkIcon />,
    title: "Shift Registration",
    description:
      "Record and manage shifts effortlessly, providing a complete work history for carers.",
  },
  {
    icon: <TimelineIcon />,
    title: "Career Timeline",
    description:
      "Carers can maintain a detailed career timeline, serving as a reference for future opportunities.",
  },
  {
    icon: <ManageAccountsIcon />,
    title: "Agency Management",
    description:
      "Efficiently manage shifts, carers, and timesheets with robust fraud prevention features.",
  },
];

export default function Highlights() {
  const theme = useTheme();

  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: "white",
        bgcolor: theme.colors.primary.main,
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Background Image */}
      <Box
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundImage: `url(${require("src/assets/highlights.png")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          opacity: 0.1, // Adjust this value to control the image opacity
          zIndex: 0,
        }}
      />

      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4">
            Highlights
          </Typography>
          <Typography variant="body1" sx={{ color: "grey.400" }}>
            Discover the key features that make our app indispensable for care
            homes, carers, and agencies. Manage residents, track medications,
            receive priority alerts, and more with ease and efficiency.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                sx={{
                  p: 3,
                  height: "100%",
                  boxShadow: "none",
                  background: `${theme.colors.alpha.white[100]}CC`,
                  backdropFilter: "blur(8px)",
                }}
              >
                <Box sx={{ color: theme.colors.primary.main }}>{item.icon}</Box>
                <div>
                  <Typography
                    fontWeight="medium"
                    gutterBottom
                    sx={{
                      color: theme.colors.primary.main,
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.colors.primary.light }}
                  >
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
