import React from "react";

const AccountDeletionGuide = () => {
  const styles = {
    container: {
      maxWidth: "800px",
      margin: "0 auto",
      fontFamily: "Arial, sans-serif",
      lineHeight: 1.6,
      color: "#333",
      padding: "20px",
    },
    h1: {
      color: "#2c3e50",
      borderBottom: "2px solid #3498db",
      paddingBottom: "10px",
    },
    h2: {
      color: "#3498db",
      marginTop: "30px",
    },
    note: {
      backgroundColor: "#f8f8f8",
      borderLeft: "4px solid #3498db",
      padding: "10px",
      margin: "20px 0",
    },
    warning: {
      backgroundColor: "#fff5f5",
      borderLeft: "4px solid #e74c3c",
      padding: "10px",
      margin: "20px 0",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.h1}>Account Deletion Guide</h1>
      <p>
        We understand that you may need to delete your account from our Care
        Management System. This guide provides step-by-step instructions for
        account deletion based on your role within the system.
      </p>

      <h2 style={styles.h2}>For Organization Admins</h2>
      <ol>
        <li>Log into your account</li>
        <li>Navigate to the "Settings" option in the side menu</li>
        <li>Click on the "General" submenu</li>
        <li>Select "Account Settings"</li>
        <li>Scroll down to the "Danger Zone" section</li>
        <li>Click on the "Delete Account" button</li>
      </ol>

      <h2 style={styles.h2}>For Other Staff Members</h2>
      <ol>
        <li>Log into your account</li>
        <li>Click on "Account Settings" in the side menu</li>
        <li>Scroll down to the "Danger Zone" section</li>
        <li>Click on the "Delete Account" button</li>
      </ol>

      <h2 style={styles.h2}>Account Deletion Process</h2>
      <p>Upon initiating the account deletion:</p>
      <ol>
        <li>A deletion request will be scheduled in our system</li>
        <li>There is a 7-day grace period to prevent unintended deletions</li>
        <li>
          After 7 days, all data related to your account will be permanently
          deleted from our system
        </li>
      </ol>

      <div style={styles.note}>
        <strong>Note:</strong> During the 7-day grace period, you can still
        access your account and cancel the deletion request if you change your
        mind.
      </div>

      <h2 style={styles.h2}>After Initiating Deletion</h2>
      <p>Once you've initiated the account deletion process:</p>
      <ul>
        <li>
          Alternatively, you can request immediate deletion if you don't wish to
          wait for 7 days
        </li>
      </ul>

      <div style={styles.warning}>
        <strong>Warning:</strong> Once the 7-day period has passed or if you
        request immediate deletion, all your data will be permanently removed
        and cannot be recovered.
      </div>

      <h2 style={styles.h2}>Need Assistance?</h2>
      <p>
        If you encounter any issues during the account deletion process or have
        any questions, please don't hesitate to contact our support team at{" "}
        <a href="mailto:info@wyecare.com">info@wyecare.com</a>.
      </p>

      <p>
        We value your privacy and are committed to making the account deletion
        process as smooth as possible. Thank you for using our Care Management
        System.
      </p>
    </div>
  );
};

export default AccountDeletionGuide;
